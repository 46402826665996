import logo from "../src/assets/logo.svg";
import dogelos from "../src/assets/dogelos.png";
import twitter from "../src/assets/twitter.svg";
import discord from "../src/assets/discord.svg";
import telegram from "../src/assets/telegram.svg";
import bonk from "../src/assets/bonk.svg";
import sbf from "../src/assets/sbf.png";
import sbfMobile from "../src/assets/sbfMobile.png";
import roadmap from "../src/assets/roadmap.svg";
import roadmapMobile from "../src/assets/roadmapMobile.png";
import memesRoadmap from "../src/assets/memesRoadmap.svg";
import roadmapMemesMobile from "../src/assets/memesRoadmapMobile.png";
import tokenomics from "../src/assets/tokenomics.png";
import tokenomicsMobile from "../src/assets/tokenomicsMobile.svg";
import tokenomicsMemes from "../src/assets/tokenomicsMemes.svg";
import tokenomicsMemesMobile from "../src/assets/tokenomicsMemesMobile.svg";
import analos from "../src/assets/analos.svg";
import analosMobile from "../src/assets/analosMobile.png";
import bgThirdScreen from "../src/assets/bgThirdScreen.svg";
import bgThirdScreenMobile from "../src/assets/thirdMobile.svg";
import screenBg from "../src/assets/bgMain.svg";
import closeMenu from "../src/assets/closeMenu.svg";
import screenBgMobile from "../src/assets/bgMainMobile.svg";
import video from "../src/assets/video.mp4";
import burgerMenu from "../src/assets/burgerMenu.svg";
import crying2 from "../src/assets/crying2.svg";
import dogehead from "../src/assets/dogehead.png";
import useMightyMouse from "react-hook-mighty-mouse";
import "./App.css";
import { motion, useScroll, useTransform } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import Lenis from "@studio-freight/lenis";

const lenis = new Lenis();



lenis.on("scroll", (e) => {});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

AOS.init();

function Main() {
  const [text, setText] = useState(
    "analos no stneve elbaemem eht lla fo noitanimluc a sa detaerC"
  );
  const [isMenuBurgerOpened, setIsMenuBurgerOpened] = useState(false);
  const [visiblePaper, setCisiblePaper] = useState(false);
  const {
    selectedElement: {
      position: { angle: angleLeftEye },
    },
  } = useMightyMouse(true, "left-eye", { x: 0, y: 0 });
  const {
    selectedElement: {
      position: { angle: angleRightEye },
    },
  } = useMightyMouse(true, "right-eye", { x: 0, y: 0 });

  const rotateLeftEye = `rotate(${-angleLeftEye - 110}deg)`;
  const rotateRightEye = `rotate(${-angleRightEye - 110}deg)`;
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2]);
  return (
    <div className="App">
      {isMenuBurgerOpened && (
        <div className="OpenedBurgerMenu">
          <img
            className="close-menu"
            onClick={() => setIsMenuBurgerOpened(false)}
            src={closeMenu}
          />
          <div className="menu-item-mobile">Main</div>
          <a
            onClick={() => setIsMenuBurgerOpened(false)}
            href="#tokenomics"
            className="menu-item-mobile not-active"
          >
            Tokenomics
          </a>
          <a
            onClick={() => setIsMenuBurgerOpened(false)}
            href="#roadmap"
            className="menu-item-mobile not-active"
          >
            Whitepaper
          </a>
          <a
            href="https://dexscreener.com/solana/69grlw4pcsypznn3xpsozcjft8vs8wa5817vuvnzngth"
            className="body-buy"
          >
            <div className="buy">Buy now</div>
          </a>
        </div>
      )}

      <div className="first-screen">
        <img className="crying" src={crying2} />
        <img className="crying2" src={crying2} />
        <img className="dogehead" src={dogehead} />
        <div className="eyes">
          <div
            id="left-eye"
            className="eye"
            style={{ transform: rotateLeftEye }}
          >
            <div className="pupil" />
          </div>
          <div
            id="right-eye"
            className="eye"
            style={{ transform: rotateRightEye }}
          >
            <div className="pupil" />
          </div>
        </div>
        <div className="header-mobile">
          <div className="logo">
            <img src={logo} />
          </div>
          <img onClick={() => setIsMenuBurgerOpened(true)} src={burgerMenu} />
        </div>
        <div className="header">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="menu">
            <div className="menu-item">Main</div>
            <a href="#tokenomics" className="menu-item not-active">
              Tokenomics
            </a>
            <div
              onClick={() => {
                if (!visiblePaper) {
                  setCisiblePaper(true);
                  setTimeout(() => {
                    setCisiblePaper(false);
                  }, 6000);
                }
              }}
              className="menu-item not-active"
            >
              Whitepaper
            </div>
            <div class="loader">
              <div
                class="loader__element"
                style={{ display: visiblePaper ? "block" : "none" }}
              >
                <div class="loader__inner"></div>
              </div>
            </div>
          </div>
          <a
            href="https://dexscreener.com/solana/69grlw4pcsypznn3xpsozcjft8vs8wa5817vuvnzngth"
            className="body-buy"
          >
            <div className="buy">Buy now</div>
          </a>
        </div>
        <div className="container">
          <div className="los">
            <img src={dogelos} />
          </div>
          <div className="los-mobile">
            <img src={analosMobile} />
          </div>
          <div className="los-text">Look Right, Move Left</div>
          <div
            onMouseEnter={() => {
              setText(
                "Created as a culmination of all the memeable events on solana"
              );
            }}
            onMouseLeave={() => {
              setText(
                "analos no stneve elbaemem eht lla fo noitanimluc a sa detaerC"
              );
            }}
            className="los-text-small"
          >
            {text}
          </div>
          <div style={{ zIndex: 1000 }} className="mediablock">
            <div className="mediablock-inside">
              Our Social Media
              <a
                href="https://twitter.com/ana_iykyk"
                target="__blank"
                className="social-item"
              >
                <img src={twitter} />
              </a>
              <a
                href="https://t.me/analos_coin"
                target="__blank"
                className="social-item"
              >
                <img src={telegram} />
              </a>
            </div>
            <span className="contract-text">
              Contract: 7iT1GRYYhEop2nV1dyCwK2MGyLmPHq47WhPGSwiqcUg5
            </span>
          </div>
        </div>
        <div className="footer-line">
          <div className="scroll">
            <div className="RightToLeft">
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
              <p>Buy now</p>
              <p>/</p>
            </div>
          </div>
        </div>
        <img className="bonk" src={bonk} />
        <img className="sbf" src={sbf} />
        <img className="sbf-mobile" src={sbfMobile} />
        <div className="first-screen-bg">
          <img src={screenBg} />
        </div>
        <div className="first-screen-bg-mobile">
          <img src={screenBgMobile} />
        </div>
      </div>
      <div className="second-screen">
        <div className="roadmap-title">ROADMAP</div>
        <div className="roadmap-text">
          PUMP, PUMP, PUMP, PUMP, PUMP, PUMP, PUMP, PUMP,
          <br />
          DUMP, PUMP, PUMP, DUMP, PUMP, PUMP, DUMP, DUMP
        </div>
        <img id="roadmap" className="roadmap" src={roadmap} />
        <img className="roadmap-mobile" src={roadmapMobile} />
        <img className="roadmapMemesMobile" src={roadmapMemesMobile} />
        <img className="memes-roadmap" src={memesRoadmap} />
      </div>
      <div style={{ scale }} className="third-screen">
        <div data-aos="fade-up" className="third-screen-container">
          <img className="bgThirdScreen" src={bgThirdScreen} />
          <img className="bgThirdScreenMobile" src={bgThirdScreenMobile} />
          <div className="third-bg-img">
            <div className="third-text">
              GRAB YOUR ANALOS AND MOONWALK TO THE MOON – IT'S THE ONLY WAY TO
              GO FORWARD
            </div>
            <a
              href="https://dexscreener.com/solana/69grlw4pcsypznn3xpsozcjft8vs8wa5817vuvnzngth"
              className="third-btn"
            >
              Buy now
            </a>
          </div>
        </div>
      </div>
      <div
        id="tokenomics"
        data-aos="zoom-in"
        className="fourth-screen"
        style={{ scale }}
      >
        <div className="roadmap-title">TOKENOMICS</div>
        <div className="tokenomics">
          <img className="tokenomics-img" src={tokenomics} />
          <img className="tokenomics-img-mobile" src={tokenomicsMobile} />
          <img
            className="tokenomics-memes-img-mobile"
            src={tokenomicsMemesMobile}
          />
          <img className="tokenomics-memes-img" src={tokenomicsMemes} />
          <img className="tokenomics-analos-img" src={analos} />
        </div>
        <div className="line-footer">© Copyright 2023. All RIghts Reserved</div>
      </div>
    </div>
  );
}

export default Main;
