import "./App.css";
import "aos/dist/aos.css";
import Main from "./Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Claim from "./Claim";
import WalletConnectionProvider from "./WalletConnector";
import axios from "axios";

export const axiosConfig = {
  baseURL: "https://www.analos.pro/v1/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const api = axios.create(axiosConfig);

function App() {
  return (
    <BrowserRouter>
      <WalletConnectionProvider>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/claim" element={<Claim />} />
        </Routes>
      </WalletConnectionProvider>
    </BrowserRouter>
  );
}

export default App;
