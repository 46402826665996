import "./App.css";
import { motion, useScroll, useTransform } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useMemo, useState } from "react";
import logo from "../src/assets/logo.svg";
import separator from "../src/assets/separator.svg";
import bgLine from "../src/assets/bgLine.png";
import claimMem from "../src/assets/claimMem.png";
import bgClaim from "../src/assets/bgClaim.png";
import closeMenu from "../src/assets/closeMenu.svg";
import burgerMenu from "../src/assets/burgerMenu.svg";
import copy from "../src/assets/copy.svg";
import x from "../src/assets/x.svg";
import Lenis from "@studio-freight/lenis";
import Countdown from "react-countdown";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
import {
  CoinbaseWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import WalletContainer from "./WalletContainer";
import { useWallet } from "@solana/wallet-adapter-react";
import { api } from "./App";
import { serialize } from "borsh";
import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
  sendAndConfirmTransaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as anchor from "@coral-xyz/anchor";
import idl from "./merkle_distributor.json"; // Path to your IDL file
import { Program, AnchorProvider, web3 } from "@coral-xyz/anchor";
import { getOrCreateATA, TOKEN_PROGRAM_ID, u64 } from "@saberhq/token-utils";
import { createAssociatedTokenAccountIdempotentInstruction } from "@solana/spl-token";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { useSearchParams } from "react-router-dom";

window.Buffer = window.Buffer || require("buffer").Buffer;

const programId = new anchor.web3.PublicKey(
  "9zEqte8gjsGkJLe8N8BsuH7CU9Sn9dCET8tVUCuZwY9r"
); // Replace with your program ID
const network = anchor.web3.clusterApiUrl("mainnet-beta"); // or 'mainnet-beta', 'testnet'
const connection = new anchor.web3.Connection(
  "https://capable-muddy-isle.solana-mainnet.quiknode.pro/daaacd6d1c436402d13a200338cf71f3a9fb1d83/"
);

async function claimTokens(
  wallet,
  index,
  amount,
  proof,
  publicKey,
  reffererId
) {
  // Configure the local provider
  const provider = new AnchorProvider(
    connection,
    wallet,
    AnchorProvider.defaultOptions()
  );
  anchor.setProvider(provider);

  // Load the program
  const program = new anchor.Program(idl, programId, provider);

  try {
    // Call the 'claim' function from your Solana program
    const distributorPublicKey = new anchor.web3.PublicKey(
      "6Hn1TYunDxqWJ3iFtNkC43FLRPWKdRShtApj8GgqEUfu"
    );
    const fromKey = new anchor.web3.PublicKey(
      "FFkSij7pWsieyhGzJc87V1kE4wAdSgaecw5heHokNfS3"
    );

    const refferer = reffererId
      ? new anchor.web3.PublicKey(reffererId)
      : new anchor.web3.PublicKey(
          "5BwMoVW3pC5vKD5BZgHnr41imLVdVYfV2CB4nLhSmza2"
        );

    const tokenId = new anchor.web3.PublicKey(
      "7iT1GRYYhEop2nV1dyCwK2MGyLmPHq47WhPGSwiqcUg5"
    );

    const recipientTokenAccount = await getAssociatedTokenAddress(
      tokenId, // The mint of the token
      wallet.publicKey // The owner of the new account
    );
    const createATAInstruction =
      createAssociatedTokenAccountIdempotentInstruction(
        wallet.publicKey, // Owner of the new account
        recipientTokenAccount,
        wallet.publicKey, // Payer of the transaction
        tokenId // Token Mint
      );

    const [claimStatusPublicKey, _bump] =
      await anchor.web3.PublicKey.findProgramAddress(
        [
          new Uint8Array(anchor.utils.bytes.utf8.encode("ClaimStatus")),
          new Uint8Array(new anchor.BN(index).toArray("le", 8)),
          distributorPublicKey.toBytes(),
        ],
        program.programId
      );

    function hexStringToUint8Array(hexString) {
      if (hexString.length % 2 !== 0) {
        throw new Error("Invalid hexString");
      }

      const byteArray = new Uint8Array(hexString.length / 2);

      for (let i = 0, j = 0; i < hexString.length; i += 2, j++) {
        byteArray[j] = parseInt(hexString.substring(i, i + 2), 16);
      }

      return byteArray;
    }

    const uint8Arrays = JSON.parse(proof.replace(/'/g, '"')).map(
      hexStringToUint8Array
    );

    const claimInstruction = program.instruction.claim(
      _bump,
      new anchor.BN(index), // Convert index to a BigNumber
      new anchor.BN(amount), // Convert amount to a BigNumber
      uint8Arrays, // Merkle proof as an array of byte arrays
      {
        accounts: {
          distributor: distributorPublicKey,
          claimStatus: claimStatusPublicKey,
          from: fromKey,
          referrer: refferer,
          to: recipientTokenAccount,
          claimant: wallet.publicKey,
          payer: wallet.publicKey,
          systemProgram: anchor.web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      }
    );

    let transaction = new Transaction();

    transaction.add(createATAInstruction);
    transaction.add(claimInstruction);

    transaction.recentBlockhash = (
      await provider.connection.getRecentBlockhash()
    ).blockhash;

    transaction.feePayer = wallet.publicKey;

    console.log(
      transaction
        .serialize({ requireAllSignatures: false, verifySignatures: false })
        .toString("base64")
    );

    const signedTransaction = await wallet.signTransaction(transaction);

    console.log(signedTransaction.serialize().toString("base64"));

    const signature = await provider.connection.sendRawTransaction(
      signedTransaction.serialize()
    );
    await provider.connection.confirmTransaction(signature);

    // await program.rpc.claim(
    //   _bump,
    //   new anchor.BN(index), // Convert index to a BigNumber
    //   new anchor.BN(amount), // Convert amount to a BigNumber
    //   uint8Arrays, // Merkle proof as an array of byte arrays
    //   {
    //     accounts: {
    // distributor: distributorPublicKey,
    // claimStatus: claimStatusPublicKey,
    // from: fromKey,
    // referrer: refferer,
    // to: publicKey.toBase58(),
    // claimant: wallet.publicKey,
    // payer: wallet.publicKey,
    // systemProgram: anchor.web3.SystemProgram.programId,
    // tokenProgram: TOKEN_PROGRAM_ID,
    //     },
    //   }
    // );

    console.log("Tokens claimed successfully");
  } catch (error) {
    console.error("Error claiming tokens:", error);
  }
}

function Claim() {
  const [isMenuBurgerOpened, setIsMenuBurgerOpened] = useState(false);
  const [availablePoints, setAvailablePoints] = useState(0);
  const [visiblePaper, setCisiblePaper] = useState(false);
  const [isInviteFriendOpen, setIsInviteFriendOpen] = useState(false);
  const [multi, setMulti] = useState(0);
  const [widthInput, setWidth] = useState("0%");
  const [snowNotEligible, setShowNotEligible] = useState(false);
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get("id");
  console.log("searchParams");
  console.log(searchParams.get("id"));
  console.log("searchParams");
  const wallet = useWallet();

  const { publicKey } = useWallet();

  if (publicKey) {
    console.log("Address of the connected wallet:", publicKey.toBase58());
  }

  useEffect(() => {
    if (publicKey) {
      api.get("/airdrop/" + publicKey.toBase58()).then((res) => {
        console.log("res.data");
        console.log(res.data.amount / 1000000000);
        console.log("res.data");
        setAvailablePoints(res.data.amount / 100000000);
      });
    }
  }, [publicKey]);

  useEffect(() => {
    api
      .post(
        "https://capable-muddy-isle.solana-mainnet.quiknode.pro/daaacd6d1c436402d13a200338cf71f3a9fb1d83/",
        {
          jsonrpc: "2.0",
          id: 1,
          method: "getTokenAccountBalance",
          params: ["FFkSij7pWsieyhGzJc87V1kE4wAdSgaecw5heHokNfS3"],
        }
      )
      .then((res) => {
        console.log(res.data.result.value.uiAmount);
        console.log(res.data.result.value.uiAmount < 1500000000);
        if (res.data.result.value.uiAmount < 1500000000) {
          setMulti(0.25);
        } else if (
          res.data.result.value.uiAmount > 1500000000 &&
          res.data.result.value.uiAmount < 3000000000
        ) {
          setMulti(0.5);
        } else if (
          res.data.result.value.uiAmount > 3000000000 &&
          res.data.result.value.uiAmount < 6000000000
        ) {
          setMulti(0.75);
        } else if (
          res.data.result.value.uiAmount > 6000000000 &&
          res.data.result.value.uiAmount < 9000000000
        ) {
          setMulti(1);
        } else if (
          res.data.result.value.uiAmount > 9000000000 &&
          res.data.result.value.uiAmount < 12000000000
        ) {
          setMulti(2);
        } else if (
          res.data.result.value.uiAmount > 12000000000 &&
          res.data.result.value.uiAmount <= 15000000000
        ) {
          setMulti(3);
        }
      });
  }, []);

  const inviteOpen = () => {
    if (publicKey) {
      setIsInviteFriendOpen(true);
    }
  };

  const onClickClaim = () => {
    if (publicKey) {
      api
        .get("/airdrop/" + publicKey.toBase58())
        .then((res) => {
          setAvailablePoints(res.data.amount / 1000000000);
          claimTokens(
            wallet,
            res.data.index,
            res.data.amount,
            res.data.proof,
            publicKey,
            searchId
          )
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.error("Error claiming tokens:", error);
            });
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          if (error.request.status == 500) {
            setShowNotEligible(true);
          }
          console.error("Error claiming tokens:", error.request.status);
        });
    }
  };

  useEffect(() => {
    console.log("multi");
    console.log(multi);
    console.log("multi");
    if (multi == 3) {
      setWidth("100");
    }
    if (multi == 2) {
      setWidth("90");
    }
    if (multi == 2) {
      setWidth("80");
    }
    if (multi == 1) {
      setWidth("60");
    }
    if (multi == 0.75) {
      setWidth("40");
    }
    if (multi == 0.5) {
      setWidth("20");
    }
    if (multi == 0.25) {
      setWidth("10");
    }
  }, [multi]);

  return (
    <div className="App">
      {snowNotEligible && (
        <div className="ModalWrap">
          <div
            style={{ width: "fit-content", height: "fit-content" }}
            className="invite-friend"
          >
            <div className="top-invite">Not your lucky day</div>
            <div className="bottom-invite">
              Sorry, you are not eligible for airdrop {":("}
            </div>
            <img
              className="x-btn"
              onClick={() => {
                setShowNotEligible(false);
              }}
              src={x}
            />
          </div>
        </div>
      )}
      {isInviteFriendOpen && (
        <div className="ModalWrap">
          <div className="invite-friend">
            <div className="top-invite">Invite Friends</div>
            <div className="bottom-invite">
              10% of your referrals' airdrop will be airdropped directly on your
              wallet
            </div>
            <img
              className="x-btn"
              onClick={() => {
                setIsInviteFriendOpen(false);
              }}
              src={x}
            />
            <div className="input-invite">
              <span>
                {" "}
                https://www.analos.meme/claim?id={publicKey?.toBase58()}
              </span>
              <CopyToClipboard
                text={`https://www.analos.meme/claim?id=${publicKey?.toBase58()}`}
              >
                <img className="img-copy" src={copy} />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}
      {isMenuBurgerOpened && (
        <div className="OpenedBurgerMenu">
          <img
            className="close-menu"
            onClick={() => setIsMenuBurgerOpened(false)}
            src={closeMenu}
          />
          <a href="/" className="menu-item-mobile">
            Main
          </a>
          <a
            onClick={() => setIsMenuBurgerOpened(false)}
            href="/#tokenomics"
            className="menu-item-mobile not-active"
          >
            Tokenomics
          </a>
          <a
            onClick={() => setIsMenuBurgerOpened(false)}
            href="#roadmap"
            className="menu-item-mobile not-active"
          >
            Whitepaper
          </a>
          <WalletContainer />
          <a
            href="https://dexscreener.com/solana/69grlw4pcsypznn3xpsozcjft8vs8wa5817vuvnzngth"
            className="body-buy"
          >
            <div className="buy">Buy now</div>
          </a>
        </div>
      )}
      <img className="pepe" src={bgClaim} />
      <img className="line-claim" src={bgLine} />
      <img className="analos-guy" src={claimMem} />
      <div className="header-mobile">
        <div className="logo">
          <img src={logo} />
        </div>
        <img
          style={{ zIndex: 5000000 }}
          onClick={() => setIsMenuBurgerOpened(true)}
          src={burgerMenu}
        />
      </div>
      <div className="header">
        <div className="logo">
          <img src={logo} />
        </div>
        <div className="menu">
          <a href="/#tokenomics" className="menu-item">
            Main
          </a>
          <a href="/#tokenomics" className="menu-item not-active">
            Tokenomics
          </a>
          <div
            onClick={() => {
              if (!visiblePaper) {
                setCisiblePaper(true);
                setTimeout(() => {
                  setCisiblePaper(false);
                }, 6000);
              }
            }}
            className="menu-item not-active"
          >
            Whitepaper
          </div>
          <div class="loader">
            <div
              class="loader__element"
              style={{ display: visiblePaper ? "block" : "none" }}
            >
              <div class="loader__inner"></div>
            </div>
          </div>
        </div>
        <div className="claim-btn-group">
          <WalletContainer />
          <a
            href="https://dexscreener.com/solana/69grlw4pcsypznn3xpsozcjft8vs8wa5817vuvnzngth"
            className="body-buy"
          >
            <div className="buy">Buy now</div>
          </a>
        </div>
      </div>
      <div className="container-claim">
        <div className="claim-block">
          <div className="claim-block-body">
            <div className="claim-text-title">GRAB YOUR ANALOS NOW</div>
            <div className="claim-description">
              A total of 15,000,000,000 ANALOS tokens are now available for
              claim by: top 30% Solana DEX users; top 100,000 $BONK holders;
              Tensorians, SMB, Mad Lads, Claynosaurz, Okay Bears, and FFF NFT
              holders.
              <br />
              <br />
              The earlier you claim, the more tokens you will receive, so hurry
              up!
            </div>
            {/* <div className="timer">
              <Countdown
                daysInHours
                renderer={(props) => {
                  console.log(props.formatted.minutes);
                  const minutesFormatted = Array.from(props.formatted.minutes);
                  const hoursForamtted = Array.from(props.formatted.hours);
                  const secondsFormatted = Array.from(props.formatted.seconds);
                  return (
                    <span className="numbers">
                      <div className="number">{hoursForamtted[0]}</div>
                      <div className="number">{hoursForamtted[1]}</div>
                      <div className="separator">
                        <img src={separator} />
                      </div>
                      <div className="number">{minutesFormatted[0]}</div>
                      <div className="number">{minutesFormatted[1]}</div>
                      <div className="separator">
                        <img src={separator} />
                      </div>
                      <div className="number">{secondsFormatted[0]}</div>
                      <div className="number">{secondsFormatted[1]}</div>
                    </span>
                  );
                }}
                date={"2023-12-20T00:00:00"}
              />
            </div> */}

            <div className="progressbar">
              <div className="top-progress">
                <div className="current-all">
                  Current airdrop allocation
                  <span> {availablePoints * multi} $analos</span>
                </div>
                <div className="coins-now">
                  <div className="coins-now-body">
                    15 000 000 000 <span>$analos</span>
                  </div>
                </div>
                <div className="current-all">
                  Current airdrop multiplier
                  <span>{multi}x</span>
                </div>
              </div>
              <div className="bottom-progress">
                <div
                  style={{ width: widthInput + "%", marginRight: "auto" }}
                  className="progress-main"
                ></div>
              </div>
            </div>
            <div className="btn-group">
              <div onClick={onClickClaim} className="btn-claim">
                Claim
              </div>
              <div className="btn-invite">
                <div onClick={inviteOpen} className="btn-invite-body">
                  Invite friends
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Claim;
